import styled from 'styled-components';

export const AccountViewWrapper = styled.div`
  #banner {
    background-color: ${props => `${props.theme.color.primaryColor}`};
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='${props =>
      props.theme.color.primaryColor.replace(
        /#/g,
        '%',
      )}' fill-opacity='0.3'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
    width: 100%;
    display: flex;
    height: 60px;
    position: ${props => (props.isMobile === true ? '' : 'sticky')}
    top: ${props => (props.isMobile === true ? '' : props.bannerTopPosition)};
    z-index: 2;
    .banner {
      margin: auto;
      display: flex;
      padding: 0;
      .centrer-helper {
        opacity: 0;
        z-index: -1;
        margin-left: 1rem;
      }
      .hello {
        text-transform: uppercase;
        font-family: ${props => props.theme.font.secondaryFont.name};
        font-weight: ${props =>
          parseInt(props.theme.font.secondaryFont.weight) + 300};
          color: white;
          text-align: center;
          margin: 0;
          font-size: ${props =>
            `${(29 * props.theme.font.secondaryFont.scaling) / 100}px`};
          }
          .banner-favorites {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: white;
            font-size: ${props =>
              `${(16 * props.theme.font.primaryFont.scaling) / 100}px`};

              i {
                font-size: 35px;
                margin: 0 10px;
                background: white;
                color: ${props => props.theme.color.primaryColor};
                padding: 4px;
                border-radius: 50%;

                &:hover {
                  cursor: pointer;
                }
              }
            }
    }
  }
  .fake-banner {
    height: ${props => props.fakeBannerHeight};
    background: white;
    position: sticky;
    top: ${props => (props.isMobile === true ? '' : props.bannerTopPosition)};
    z-index: 1;
  }
  .banner.secrets {
    justify-content: flex-end;
    .add-secret {
      background: none;
      border: none;
      color: white;
      font-size: ${props =>
        `${(16 * props.theme.font.primaryFont.scaling) / 100}px`};
      display: flex;
      align-items: center;
      .adn-plus-circle {
        font-size: 35px;
      }
    }
  }
  .banner.profile {
    justify-content: ${props =>
      props.isMobile === true ? 'center' : 'space-between'};
    align-items: center;
    .logout {
      display: flex;
      align-items: center;
      border-radius: 5px;
      color: ${props => props.theme.color.primaryColor};
      background: white;
      padding: 5px 10px;
      cursor: pointer;

      .adn-power {
        margin-right: 10px;
        font-size: 1.8rem;
      }
    }
  }
  .profile-tab-container {
    display: flex;
    justify-content: center;
  }
`;
