import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

export const AccountSecretsTabWrapper = styled.div`
  padding: 30px 80px;
  height: ${props => `${props.bodyHeight}`};
  display: flex;
  justify-content: center;
  .no-secrets {
    display: flex;
    .block {
      max-height: 500px;
    }
    .help-first-secret {
      background: #313131;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      border: 1px dashed gray;
      .adn-star-circle {
        font-size: 80px;
        margin-bottom: 20px;
      }
      .description p {
        color: ${props => `${props.theme.color.primaryColor}`};
      }
    }
    .whisper-secret {
      border: 1px dashed;
      padding: 10px;
      .secret-photo {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 50px;
      }
      .description {
        padding: 0 10px;
        .firstName {
          font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
        }
        .button {
          display: flex;
          justify-content: center;
          margin-top: 30px;
          button {
            border: none;
            background: none;
            .adn-plus-circle {
              font-size: 100px;
              color: ${props => `${props.theme.color.primaryColor}`};
            }
          }
        }
      }
    }
  }
  .secrets-container {
    display: flex;
    flex-direction: column;
    .secret {
      margin-bottom: 2rem;
    }
  }
  @media (min-width: ${isMobileBreakpoint}px) {
    .help-first-secret {
      margin-right: 25px;
      flex-basis: 100%;
    }
    .whisper-secret {
      margin-left: 25px;
      flex-basis: 100%;
    }
  }
  @media (max-width: ${isMobileBreakpoint-1}px) {
    height: 100%;
    padding: 20px 20px;
    .no-secrets {
      flex-direction: column;
      .help-first-secret {
        padding: 10px 20px;
        margin-bottom: 20px;
      }
      .whisper-secret {
        .secret-photo {
          display: none;
        }
        .description {
          display: flex;
          align-items: center;
          p {
            margin-bottom: 0;
          }
          .adn-plus-circle {
            margin-top: 0;
            font-size: 80px;
          }
        }
      }
    }
  }
`;
