import styled from 'styled-components';

export const ModalWrapper = styled.div`
  transition: opacity .05s cubic-bezier(0.4,0.0,0.2,1);
  background-color: rgba(0,0,0,0.502);
  bottom: 0;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 30000001;
  .modal-block-container {
    transition: transform .4s cubic-bezier(0.4,0.0,0.2,1);
    align-items: center;
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: 0;
    padding: 0 5%;
    position: absolute;
    right: 0;
    top: 0;
    .modal-centering {
      flex-grow: 1;
      display: block;
      height: 3em;
    }
    .modal-block {
      position: relative;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      transition: transform .225s cubic-bezier(0.0,0.0,0.2,1);
      background: white;
      border-radius: 2px;
      box-shadow: 0 12px 15px 0 rgba(0,0,0,0.24);
      padding-bottom: 24px;
      .modal-title {
        display: flex;
        padding: 24px 24px 20px 24px;
        font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      }
      .inputs {
        display: flex;
        flex-direction: column;
        input:not(:first-child) {
          margin-top: 1rem;
        }
      }
      .textarea {
        padding: 0 24px;
        textarea {
          width: 300px;
          min-height: 200px;
          border: 1px solid #d6d2d2;
          border-radius: 3px;
          padding: 4px;
        }
      }
      .error {
        margin: 0 24px;
        margin-top: 1rem;
        font-size: ${props => `${13*props.theme.font.primaryFont.scaling/100}px`};
        text-align: center;
        font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      }
      .buttons-container {
        display: flex;
        justify-content: space-evenly;
        margin-top: 1rem;
        button {
          background: none;
          border: none;
        }
      }
      input {
        margin: 0 24px;
        width: 300px;
        border: 1px solid #d6d2d2;
        border-radius: 3px;
        padding: 4px;
      }
    }
  }
`;
