import styled from 'styled-components';

export const UserSecretWrapper = styled.div`
  border: 1px solid #e7e7e7;
  margin-right: ${props => (props.isMobile === true ? '' : '30px')};
  max-width: ${props => (props.nbSecrets === 1 ? '500px' : '')};
  margin: ${props => (props.nbSecrets === 1 ? 'auto' : '')};
  button {
    background: ${props => (props.isMobile === true ? '' : 'none')};
    border: ${props => (props.isMobile === true ? '' : 'none')};
  }
  .whisper {
    color: #79797a;
    margin: 10px 15px
  }
  .secret-container {
    background: #f9f9f9;
    padding: 10px;
    .img-container {
      position: relative;
      margin-bottom: 1rem;
      .secret-photo {
        height: 250px;
        width: 100%;
        object-fit: cover;
      }
      button {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .adn-magnify-plus-outline {
        font-size: 40px;
        color: white;
      }
    }
    .secret-title {
      overflow: hidden;
      height: ${props => (props.isMobile === true ? '' : '33px')};
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      }
    .description {
      position: relative;
      .description-text {
        height: 100px;
        overflow-y: auto;
      }
      .description-fade-out {
        height: 10px;
        position: absolute;
        width: 100%
        &.fade-out-top {
          top: -1px;
          background: linear-gradient(rgb(247,247,247), rgba(247,247,247,0));
        }
        &.fade-out-bottom {
          background: linear-gradient(0deg, rgb(247,247,247), rgba(247,247,247,0));
          bottom: -1px;
        }
      }
    }
    .product-container {
      background: white;
      height: 120px;
      display: flex;
      border: 1px solid #e7e7e7;
      .product-photo {
        height: 100%;
        width: ${props => (props.isMobile === true ? '40%' : '33%')};
        object-fit: cover;
      }
      .product-texts {
        padding: 10px;
      }
    }
    .location-container {
      background: white;
      height: 120px;
      display: flex;
      border: 1px solid #e7e7e7;
      .location-map {
        height: 100%;
        width: ${props => (props.isMobile === true ? '40%' : '33%')};
      }
      .location-texts {
        display: flex;
        align-items: center;
        padding: 10px;
      }
    }
  }
  .status {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0
    }
    .accepted {
      color: #7ed321;
    }
    .pending {
      color: #ffc107;
    }
    .refused {
      color: #dc3545;
    }
    .reason {
      display: ${props => (props.isMobile === true ? 'none' : 'inherit')};
    }
    .edition {
      display: flex;
      button {
        border: none;
        background: none;
      }
    }
  }
`;
