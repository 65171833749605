import styled from 'styled-components';

export const SecretInsertWrapper = styled.div`
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 1rem;
  margin-right: 0;
  margin: ${props => (props.isMobile === true ? '2rem 1rem' : '')};
  padding: 0 1rem 0.5rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  position: ${props => (props.isMobile === true ? '' : 'sticky')};
  top: ${props => (props.isMobile === true ? '' : props.topPosition)};
  .super-whisperer-container {
    text-align: center;
  }
  .super-whisperer {
    width: 125px;
    height: 125px;
    object-fit: cover;
    margin-top: 0.5rem;
  }
  .adn {
    font-size: 100px;
    color: ${props => {
      return props.theme.color.primaryColor;
    }};
  }
  .add-secret {
    background: none;
    border: none;
  }
  .colored {
    color: ${props => {
      return props.theme.color.primaryColor;
    }};
  }
  .thanks {
    text-transform: uppercase;
    font-family: ${props => props.theme.font.secondaryFont.name};
    font-weight: ${props =>
      parseInt(props.theme.font.secondaryFont.weight) + 300};
    font-size: ${props =>
      `${(24 * props.theme.font.secondaryFont.scaling) / 100}px`};
    margin-top: 0.5rem;
  }
  p {
    margin: 0;
  }
`;
