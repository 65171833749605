import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from "ToolboxComponents/commons/sliders/slider/slider/slider";
import UserSecretsSlide from 'ToolboxComponents/commons/cards/user-secrets-product-card/user-secrets-product-card';

const SliderSecrets = ({ secrets, onDelete, editSecret }) => {
  const [nbSecrets, setNbSecrets] = useState(secrets.length);
  const [sliderShowed, showSlider] = useState(true);
  useEffect(() => {
    setNbSecrets(secrets.length);
    showSlider(false);
    setTimeout(function() {
      showSlider(true);
    }, 100);
  }, [secrets.length]);
  return (
    sliderShowed ?
      <Slider
        desktop={{
          slidesPerView: secrets.length === 1 ? 1 : 2,
          dots: true,
          arrows: true
        }}
      >
        {secrets.map((secret, index) => (
          <UserSecretsSlide
            key={index}
            nbSecrets={secrets.length}
            secret={secret}
            editSecret={secret => editSecret(secret)}
            onDelete={id => onDelete(id)}
          />
        ))}
      </Slider>
      : null
  );
};

SliderSecrets.propTypes = {
  secrets: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  editSecret: PropTypes.func.isRequired,
}

export default SliderSecrets;
