import styled from 'styled-components';

export const AccountProfileTabWrapper = styled.div`
  margin-top: ${props => props.isMobile === true ? '0' : props.fakeBannerHeight};
  width: 100%;
  height: 100%;
  display: ${props => (props.isMobile === true ? '' : 'grid')};
  grid-template-columns: ${props => (props.isMobile === true ? '' : '3fr 1fr')};
  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: ${props => (props.isMobile === true ? '' : 'flex-start')};
    margin-top: ${props => (props.isMobile === true ? '1rem' : '0')};
    margin-left: ${props => (props.isMobile === true ? '' : '4rem')};
    margin-bottom: 2rem;
    .validation {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-bottom: 30px;
      margin-top: 1rem;
      text-align: center;
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      .email {
        margin: auto;
        background: none;
        border: none;
        text-decoration: underline;
        font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      }
    }
    .edit-profile-picture {
      width: 187px;
      height: 226px;
      margin: ${props => (props.isMobile === true ? 'auto' : '')};
      margin-left: ${props => (props.isMobile === true ? '' : '1rem')};
      // width: ${props => (props.isMobile === true ? '' : '175px')};
      background: none;
      border: none;
    }
    .edit-profile-picture:hover {
      cursor: pointer;
    }
    .public-profile {
      display: flex;
      justify-content: center;
      margin-top: ${props => (props.isMobile === true ? '1rem' : '3rem')};
      margin-bottom: ${props => (props.isMobile === true ? '' : '1rem')};
      margin-left: ${props => (props.isMobile === true ? '' : '1rem')};
      a {
        color: #212529;
        display: flex;
        flex-direction: ${props => (props.isMobile === true ? 'column' : 'row')};
        align-items: center;
        .adn-open-in-new {
          margin-right: ${props => (props.isMobile === true ? '' : '.5rem')};
        }
      }
      .adn-open-in-new {
        font-size: 2rem;
        color: #606060;
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      margin: 16px;
      width: ${props => (props.isMobile === true ? '' : '500px')};
      .field {
        margin-top: ${props => (props.isMobile === true ? '.8rem' : '1.5rem')};
        label {
          font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
        }
        .input-line {
          display: flex;
          align-items: center;
          .edit-field-button {
            background: none;
            border: none;
            .adn-pencil {
              font-size: 25px;
              margin-left: .5rem;
            }
          }
        }
      }
      input,textarea {
        border: 1px solid #dcdcdc;
        border-radius: 5px;
        padding: .5rem;
        width: 100%;
      }
      input[type="submit"] {
        width: 100%;
        box-shadow: 0px 0px 10px #000000;
        left: 0;
        bottom: 31px;
        position: fixed;
        z-index: 30;
        width: 100%;
        margin-top: 10px;
        background: ${props => { return props.theme.color.primaryColor }};
        border: ${props => { return `2px solid ${props.theme.color.primaryColor}` }};
        color: #fff;
      }
    }
    .options {
      width: ${props => (props.isMobile === true ? '' : '500px')};
      .option {
        margin: 1rem;
        margin-right: 1.5rem;
        .slider-title {
          font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
        }
      }
    }
    .logout {
      display: flex;
      align-items: center;
      border-radius: 5px;
      background: ${props => props.theme.color.primaryColor};
      color: white;
      margin: auto;
      margin-top: 1rem;
      padding: 8px;
      .adn-power {
        margin: -5px 5px -10px 0;
        font-size: 1.8rem;
      }
    }
    .account-delete {
      margin: 40px 0 20px 16px;
      .delete {
        border: none;
        background: none;
        text-align: left;
      }
    }
  }
  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;
