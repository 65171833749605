import React, { useEffect, useContext, useState } from 'react';
import PropTypes from "prop-types";
import { ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import Upload from 'ToolboxComponents/commons/upload/upload';
import Label from 'ToolboxComponents/commons/upload/ui/label';
import SingleUploadImage from 'ToolboxComponents/commons/upload/ui/single-upload-image';
import DefaultPhoto from "ToolboxAssets/img/default-photo.png";
import { UploadPictureWrapper } from 'ToolboxComponents/webapp/pages/account-view/components/upload-picture/upload-picture.styles';

const UploadPicture = ({ photoValue, handlePhoto, account }) => {
  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;
  const [completed, setCompleted] = useState(undefined);
  const [progress, setProgress] = useState(0);

  const onCompleteCallBack = info => {
    setCompleted(true);
    setProgress(0);
    handlePhoto(info[0]);
  };
  const onProgressCallBack = value => {
    setProgress(value);
  };

  useEffect(() => {
    if (photoValue !== '') {
      setCompleted(true);
    }
  }, [photoValue]);

  return (
    <UploadPictureWrapper className='upload-secret-image' progress={progress} theme={theme}>
      <div className='progress-bar-container'></div>
      <Upload
        key='upload-secret-image'
        className='interface'
        onComplete={onCompleteCallBack}
        onProgress={onProgressCallBack}
      >
        {photoValue === '' && progress === 0 && (
          <Label
            key='upload-label'
            clickToUpload={progress > 0 ? false : true}
          >
            <div className='profile-photo-edition'>
              {account.photo ?
                <img src={account.photo} className='profile-photo' alt='profil' />
                : <img src={DefaultPhoto} className='profile-photo' alt='profil' />
              }
              <div className='add-delete-file'>
                <div className='text'>{whiteLabel.textualContent.page.account.modifyPhoto}</div>
              </div>
            </div>
          </Label>
        )}
      </Upload>
    </UploadPictureWrapper>
  );
};

UploadPicture.propTypes = {
  photoValue: PropTypes.string.isRequired,
  handlePhoto: PropTypes.func.isRequired,
}

export default UploadPicture;
