import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, WhiteLabelContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import sha1 from 'sha1';
import { ModalWrapper } from 'ToolboxComponents/webapp/modals/modal-edit-field/modal-edit-field.styles';

import { PASSWORD_MIN_LENGTH } from 'ToolboxParams/globals';

const ModalEditField = props => {
  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;
  const [newValue, setNewValue] = useState(props.defaultValue);
  const [newDoubleCheckValue, setDoubleCheckNewValue] = useState();
  const [initialValue, setInitialValue] = useState();
  const [errorMessage, setErrorMessage] = useState();
  useEffect(() => {
    if (!props.confirmation || (newValue && newDoubleCheckValue && newValue === newDoubleCheckValue)) {
      setErrorMessage(null);
    } else {
      setErrorMessage(props.actualConfirmation ? whiteLabel.textualContent.page.account.passwordMismatch : whiteLabel.textualContent.page.account.mailMismatch);
    }
  }, [newValue,newDoubleCheckValue,props.confirmation,props.actualConfirmation]);

  const submit = event => {
    event.preventDefault();
    if (props.confirmation) {
      if (newValue === newDoubleCheckValue) {
        if(props.type === 'password') {
          if (newValue.length < PASSWORD_MIN_LENGTH) {
            setErrorMessage(whiteLabel.textualContent.page.account.passwordMinLength);
          } else {
            props.onSubmit([sha1(initialValue), sha1(newValue)]);
            props.closeModal();
          }
        } else {
          props.onSubmit(newValue);
          props.closeModal();
        }
      }
    } else if(props.type === 'textarea') {
      props.onSubmit({fr: newValue});
      props.closeModal();
    } else {
      props.onSubmit(newValue);
      props.closeModal();
    }
  }

  let placeholder = undefined;
  switch (props.name) {
    case 'firstName':
      placeholder = [whiteLabel.textualContent.page.account.inputFirstName];
      break;
    case 'email':
      placeholder = [whiteLabel.textualContent.page.account.inputEmail, whiteLabel.textualContent.page.account.inputConfirmMail];
      break;
    case 'password':
      placeholder = [whiteLabel.textualContent.page.account.inputPassword, whiteLabel.textualContent.page.account.inputRepeatPassword, whiteLabel.textualContent.page.account.inputOldPassword];
      break;
    case 'biography':
      placeholder = [whiteLabel.textualContent.page.account.inputBiography];
      break;
    default:

  }
  return (
    <ModalWrapper theme={theme}>
      <div className='modal-block-container'>
        <div className='modal-centering'></div>
        <form className='modal-block' onSubmit={(event) => submit(event)}>
          <div className='modal-title'>{props.title}</div>
          {props.type !== 'textarea' &&
            <div className='inputs'>
              {props.actualConfirmation &&
                <input
                  id={`${props.name}ActualConfirmation`}
                  name={props.name}
                  type={props.type}
                  placeholder={placeholder[2]}
                  onChange={(event) => setInitialValue(event.target.value)}
                />
              }
              <input
                id={props.name}
                name={props.name}
                type={props.type}
                defaultValue={newValue}
                placeholder={placeholder[0]}
                onChange={(event) => setNewValue(event.target.value)}
              />
              {props.confirmation &&
                <input
                  id={`${props.name}Confirmation`}
                  name={props.name}
                  type={props.type}
                  placeholder={placeholder[1]}
                  onChange={(event) => setDoubleCheckNewValue(event.target.value)}
                />
              }
            </div>
          }
          {props.type === 'textarea' &&
            <div className='textarea'>
              <textarea
                id={props.name}
                name={props.name}
                defaultValue={props.defaultValue && props.defaultValue.fr ? props.defaultValue.fr : null}
                placeholder={placeholder[0]}
                onChange={(event) => setNewValue(event.target.value)}
              />
            </div>
          }
          {errorMessage &&
            <div className='error'>
              {errorMessage}
            </div>
          }
          <div className='buttons-container'>
            <button type='button' onClick={() => props.closeModal()}>Annuler</button>
            <button type='submit' disabled={errorMessage}>Valider</button>
          </div>
        </form>
        <div className='modal-centering'></div>
      </div>
    </ModalWrapper>
  );
}

ModalEditField.propTypes = {
  title: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  confirmation: PropTypes.bool,
  actualConfirmation: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
}

export default ModalEditField;
