import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import SliderSecrets from 'ToolboxComponents/webapp/pages/account-view/components/account-slider-secrets/account-slider-secrets';
import UserSecretsSlide from 'ToolboxComponents/commons/cards/user-secrets-product-card/user-secrets-product-card';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import ModalAddSecret from 'ToolboxComponents/webapp/modals/modal-secret/modal-secret';
import SecretPhotoExample from 'ToolboxAssets/img/secret-example.png';

import { AccountSecretsTabWrapper } from 'ToolboxComponents/webapp/pages/account-view/components/account-secrets-tab/account-secrets-tab.styles';

const AccountSecretsTab = ({ account, secrets, openAddSecretModal, onDelete, updateSecret, isSecretSaved, theme, fakeBannerHeight }) => {
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [secretEdited, editSecret] = useState(null);

  const [bodyHeight, setBodyHeight] = useState(0);
  useEffect(() => {
    setTimeout(function() {
      if (secrets.length === 0) {
        const mainNav = document.getElementById('main-nav');
        let tabHeight = window.innerHeight - document.getElementById('nav-bar').offsetHeight - document.getElementById('banner').offsetHeight;
        if (mainNav) {
          tabHeight -= mainNav.offsetHeight;
        }
        setBodyHeight(`${tabHeight}px`);
      } else {
        setBodyHeight('unset');
      }
    }, 100);
  }, [secrets]);

  return (
    <AccountSecretsTabWrapper theme={theme} fakeBannerHeight={fakeBannerHeight} bodyHeight={bodyHeight}>
      {secrets.length === 0 &&
        <div className='no-secrets'>
          <div className='help-first-secret block'>
            <i className='icon adn adn-star-circle' />
            <div className='description'>
              <h4><Text path={`page.account.secretTitle`} /></h4>
              <p><Text path={`page.account.secretDescription`} /></p>
              <p><Text path={`page.account.thanksAdvices`} /></p>
            </div>
          </div>
          <div className='whisper-secret block'>
            <img className='secret-photo' src={SecretPhotoExample} />
            <div className='description'>
              <div className='text'>
                <p className='firstName'>{account.firstName},</p>
                <p><Text path={`page.account.firstSecret`} /></p>
              </div>
              <div className='button'>
                <button type='button' onClick={() => openAddSecretModal()}>
                  <i className='icon adn adn-plus-circle' />
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      {secrets.length > 0 && isMobile &&
        <div className='secrets-container'>
          {secrets.map((secret, index) => (
            <div className='secret' key={index}>
              <UserSecretsSlide
                secret={secret}
                editSecret={secret => editSecret(secret)}
                onDelete={id => onDelete(id)}
              />
            </div>
          ))}
        </div>
      }
      {secrets.length > 0 && !isMobile &&
        <SliderSecrets
          secrets={secrets}
          onDelete={id => onDelete(id)}
          editSecret={secret => editSecret(secret)}
        />
      }
      {secretEdited &&
        <ModalAddSecret
          secret={secretEdited}
          onSubmit={object => updateSecret(object.secret)}
          isSecretSaved={isSecretSaved}
          onClose={() => editSecret(null)}
        />
      }
    </AccountSecretsTabWrapper>
  );
};

AccountSecretsTab.propTypes = {
  account: PropTypes.object.isRequired,
  secrets: PropTypes.array.isRequired,
  openAddSecretModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  updateSecret: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  fakeBannerHeight: PropTypes.string.isRequired,
}

export default AccountSecretsTab;
