import styled from 'styled-components';

export const UploadPictureWrapper = styled.div`
  .progress-bar-container {
    width: 100%;
    margin-bottom: 5px;

    &:before {
      content: '';
      display: block;
      width: ${props => props.progress}%;
      height: 5px;
      background: ${props => { return props.theme.color.primaryColor }};
      transition: width 0.3s;
    }
  }
  .profile-photo-edition {
    display: flex;
    flex-direction: column;
    align-items: center;
    .profile-photo {
      height: ${props => (props.isMobile === true ? '150px' : '175px')};
      width: ${props => (props.isMobile === true ? '150px' : '175px')};
      object-fit: cover;
      border-radius: 100px;
      border: 10px solid ${props => props.theme.color.tertiaryColor};
      margin: ${props => (props.isMobile === true ? 'auto' : '')};
      margin-bottom: 15px;
    }
  }
`;
