import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import Text from 'ToolboxComponents/commons/texts/text/text';

import { SecretInsertWrapper } from './secret-insert.styles';

import SuperWhispererImg from "ToolboxAssets/img/super-whisperer-400.png";

import { SUPER_WHISPERER_MIN_SECRETS } from 'ToolboxParams/globals';

const SecretInsert = props => {
  const { firstName, secretCount, theme } = props;
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [topPosition, setTopPosition] = useState(0);
  useEffect(() => {
    setTimeout(function(){
      const mainNav = document.getElementById('main-nav');
      let top = document.getElementById('nav-bar').offsetHeight + document.getElementById('banner').offsetHeight + 16;
      if (mainNav) {
        top += mainNav.offsetHeight;
      }
      setTopPosition(`${top}px`);
    }, 100);
  }, []);
  return (
    <SecretInsertWrapper theme={theme} isMobile={isMobile} topPosition={topPosition}>
      {secretCount >= SUPER_WHISPERER_MIN_SECRETS &&
        <React.Fragment>
          <img src={SuperWhispererImg} className='super-whisperer' alt="super chuchoteur" />
          <div className='super-whisperer-container'>
            <Text path={`page.account.nbSecrets`} data={{secretCount: secretCount}} />
            <Text path={`page.account.superWhisperer`} />
            <p className='colored thanks'>
              <Text path={`page.account.thanks`} data={{firstName: firstName}} />
            </p>
          </div>
        </React.Fragment>
      }
      {secretCount > 0 && secretCount < SUPER_WHISPERER_MIN_SECRETS &&
        <React.Fragment>
          <i className='icon adn adn-star-circle' />
          <p className='colored thanks'>{firstName}</p>
          <Text path={`page.account.soonSuperWhisperer`} data={{remaining: SUPER_WHISPERER_MIN_SECRETS - secretCount, plural: ((SUPER_WHISPERER_MIN_SECRETS - secretCount) > 1 ? 's' : '')}} />
          <Text path={`page.account.become`} />
        </React.Fragment>
      }
      {secretCount === 0 &&
        <React.Fragment>
          <button type='button' className='add-secret' onClick={() => props.openAddSecretModal(true)}>
            <i className='icon adn adn-plus-circle' />
          </button>
          <p className='colored thanks'>{firstName},</p>
          <Text path={`page.account.whisper`} />
        </React.Fragment>
      }
    </SecretInsertWrapper>
  );
};

SecretInsert.propTypes = {
  secretCount: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  openAddSecretModal: PropTypes.func.isRequired,
}

export default SecretInsert;
