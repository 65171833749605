import React from 'react';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  display: ${props => (props.fullContent === true ? '' : 'flex')};
  justify-content: ${props => (props.fullContent === true ? '' : 'center')};

  .content-account {
    width: 100%;
    height: 100%;

    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }
`;

const Content = props => {

  return (
    <ContentWrapper fullContent={props.fullContent}>
      <div className={!props.fullContent && 'content-account'}>{props.children}</div>
    </ContentWrapper>
  );
};

export default Content;
