import styled from 'styled-components';

export const NavBarWrapper = styled.div`
  width: 100%;
  background: #313131;
  position: sticky;
  top: ${props => props.navBarTopPosition};
  z-index: 3;
  .nav-bar {
    margin: auto;
    padding: 16px 0;
    padding: ${props => (props.isMobile === true ? '16px 10px' : '16px 0')};
    button {
      color: white;
      background: #313131;
      border: 0;
      padding: ${props => (props.isMobile === true ? '.4rem' : '.5rem 1rem')};
    }
    button.selected {
      color: #313131;
      background: white;
    }
  }
`;
