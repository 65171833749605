import React from 'react';
// import PropTypes from "prop-types";
import styled from 'styled-components';

const ToastWrapper = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: fixed;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  transition: bottom 0.2s;

  &.visible {
    bottom: 10px;
  }

  .animated-toast {
    background: ${props => props.background};
    color: ${props => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 10px 10px;
    border-radius: 8px;

    .icon {
      margin-right: 10px;
      font-size: 30px;
    }

    .text {
    }
  }
`;

const Toast = props => {
  const { icon, text, visible } = props;
  return (
    <ToastWrapper {...props} className={`${visible === true ? 'visible' : ''}`}>
      <div className={`animated-toast`}>
        <div className={`icon adn adn-${icon}`}></div>
        <div className={`text`}>{text}</div>
      </div>
    </ToastWrapper>
  );
};

// Toast.propTypes = {};

export default Toast;
